<template>
    <b-card class="h-100">  
        <table class="mt-2 mt-xl-0 w-100">
            <tr>
                <th class="pb-75">
                    <span class="font-weight-bold">Nom complete</span>
                </th>
                <td class="pb-75">
                    {{ data.nom }}
                </td>
            </tr>
            <tr>
                <th class="pb-75">
                    <span class="font-weight-bold">Adresse</span>
                </th>
                <td class="pb-75 text-capitalize">
                    {{ data.address }}
                </td>
            </tr>
            <tr>
                <th class="pb-75">
                    <span class="font-weight-bold">Code postal</span>
                </th>
                <td class="pb-75 text-capitalize">
                    {{ data.zipcode }}
                </td>
            </tr>
            <tr>
                <th class="pb-75">
                    <span class="font-weight-bold">Téléphone</span>
                </th>
                <td class="pb-75">
                    {{ data.tel }}
                </td>
            </tr>
            <tr>
                <th class="pb-75">
                    <span class="font-weight-bold">Email</span>
                </th>
                <td class="pb-75">
                    {{ data.email }}
                </td>
            </tr>

        </table>
    </b-card>
</template>

<script>
  
  import {
    BRow, BCol, BTable, BBadge, BCard
  } from 'bootstrap-vue'


  export default {
    components: {
      BRow,
      BCol,
      BTable,
      BBadge,
      BCard
    },

    props: {
      data: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        
      }
    },

  }
</script>

<style lang="scss" scoped>
  
</style>


