<template lang="">
    <b-card no-body class="mt-2">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Afficher</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>artisans</label>
              </b-col>

                <b-col
                    cols="8"
                    class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
                >
                    <b-form-group
                        label="Abonnements"
                        label-for="abonnements"
                        class="w-25 mr-1"
                    >
                        <v-select
                            v-model="f_abonnement"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="abonnementOptions"
                            label="name"
                            :reduce="val => val.id"
                            input-id="abonnements"
                            multiple
                        />
                    </b-form-group>

                    <b-form-group
                        label="Code Postal"
                        label-for="zipcode"
                        class="w-25"
                    >
                        <b-form-input
                            v-model="f_zipcode"
                            class="d-inline-block"
                            placeholder="20000"
                            input-id="zipcode"
                        />
                    </b-form-group>
                </b-col>

              <!-- Search -->
              <b-col
                cols="2"
              >
                <div class="h-100 d-flex align-items-center justify-content-end">
                  <b-button
                    variant="primary"
                    @click="send"
                  >
                    <span class="text-nowrap">Envoyer contact</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

        </div>

         <b-table
            class="position-relative"
            responsive
            :fields="artisanColumns"
            :items="filtredArtisans"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Aucun artisan trouvé"
            :sort-desc.sync="isSortDirDesc"
        >

            <template #cell(affecter)="data">
                <b-form-checkbox
                    :ref="`checkbox-${data.item.id}`"
                    v-model="selectedArtisan"
                    :value="data.item.id"
                    :disabled="checkAcheter(data.item.id)"
                />
            </template>

            <template #cell(gratuit)="data">
                <b-form-checkbox
                    v-model="selectedArtisanG"
                    :value="data.item.id"
                    v-if="data.item.solde_free_contact > 0 && !checkAcheter(data.item.id)"
                />
            </template>

            <template #cell(nom)="data">
                <b-media vertical-align="center" class="align-items-center">
                    <template #aside>
                        <b-avatar
                            size="32"
                            :src="data.item.photo"
                            :variant="light-success"
                        />
                    </template>
                    
                    {{ data.item.nom }} 
                </b-media>
            </template>

            <template #cell(nbr_contacts)="data">
                {{ data.item.my_contacts.length }}
            </template>

        </b-table>
    </b-card>
</template>
<script>

    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
        BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BCardTitle, BCardBody, BFormCheckbox 
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { mapGetters, mapActions } from "vuex"
    import Ripple from 'vue-ripple-directive'

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BCardTitle,
            BFormCheckbox,
            vSelect,
            BFormGroup,
            ToastificationContent
        },

        props: {
            data: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                isSortDirDesc : true,
                perPage : 10,
                perPageOptions : [10, 25, 50, 100],

                artisanColumns : [
                    { label: 'Affecter', key: 'affecter', thClass: 'checkboxCol text-center', tdClass: 'checkboxCol text-center'},
                    { label: 'Gratuit', key: 'gratuit', thClass: 'checkboxCol text-center', tdClass: 'checkboxCol text-center'},
                    { label: 'Nom', key: 'nom', sortable: true, thClass: 'text-center' },
                    { label: 'Code postal', key: 'zipcode', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                    { label: 'Abonnement', key: 'abonnement', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                    { label: 'Solde actuel', key: 'solde', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                    { label: 'Solde contact gratuit', key: 'solde_free_contact', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                    { label: 'Contacts acheté', key: 'nbr_contacts', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                ],

                selectedArtisan: [],
                selectedArtisanG: [],
                f_abonnement: null,
                f_zipcode: null,
                isLoading: false,
            }
        },

        computed: {
            ...mapGetters('artisans', {'artisans' : 'GET_ITEMS'}),
            ...mapGetters('abonnements', {'abonnementOptions' : 'GET_ITEMS'}),

            filtredArtisans() {
                let artisans = this.artisans.filter(item => {
                    return item.services_ids.includes(this.data.service.service.id)
                })

                if(this.f_abonnement != null && this.f_abonnement != '') {
                    artisans = artisans.filter(item => {
                        return this.f_abonnement.includes(item.abonnement_id)
                    })
                }

                if(this.f_zipcode != null && this.f_zipcode != '') {
                    artisans = artisans.filter(item => {
                        return item.zipcode.toString().includes(this.f_zipcode)
                    })
                }

                return artisans
            },

        },

        methods: {
            ...mapActions('artisans', {'fetchArtisans' : 'fetchItems'}),
            ...mapActions('abonnements', {'fetchAbonnements' : 'fetchItems'}),
            ...mapActions('demandes', {'affecterArtisans' : 'affecter'}),

            notification(variant, title, text) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: title,
                    icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
                    text: text,
                    variant
                    },
                })
            },

            send() {
                this.$swal({
                    title: 'Êtes-vous sûr d\'envoyer le contact?',
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Annuler',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        var obj = {
                            id : this.data.id,
                            artisans : this.selectedArtisan,
                            gratuits : this.selectedArtisanG
                        }
                         let loader = this.$loading.show({
                            // Optional parameters
                            color: '#001f53',
                            loader: 'spinner',
                            backgroundColor: '#ffffff',
                            opacity: 0.5,
                            zIndex: 99999,
                            width: 64,
                            height: 64
                        });
                        this.affecterArtisans(obj).then(result => {
                            this.selectedArtisanG = []
                            this.data.artisans = result.data ? result.data.data : null
                            this.fetchArtisans()

                            var Affectation_nbrs = result.data ? result.data.data.length : 0
                            var achat_nbrs = result.data ? result.data.data.filter(item => { return item.date_achat != null }).length : 0

                            this.$emit('change_affectation_nbrs', Affectation_nbrs);
                            this.$emit('change_achat_nbrs', achat_nbrs);
                            loader.hide();
                            this.notification('success',  'Succès', 'L\'envoi de contact est bien effectuée')
                        })
                    }
                })
            },

            checkAcheter(id) {
                var artisan =  this.data.artisans ? this.data.artisans.filter(artisan => { return artisan.artisan_id == id && artisan.date_achat != null }) : []

                return !!artisan.length 
            }

        },

        created() {
            this.fetchArtisans()
            this.fetchAbonnements()
        },

        mounted() {
            this.f_zipcode =  this.data.contact.zipcode.toString().substr(0, 2)

            this.data.artisans.forEach(artisan => {
                this.selectedArtisan.push(artisan.artisan_id)
            });
        },

        watch: {
            selectedArtisan(val, oldVal) {
                var valLength = val.length
                var oldValLength = oldVal.length

                if(valLength < oldValLength) {
                    let difference = this.selectedArtisanG.filter(x => !this.selectedArtisan.includes(x));
                    if(difference.length) {
                        let index = this.selectedArtisanG.indexOf(difference[0])
                        this.selectedArtisanG.splice(index, 1)
                    }
                }

            },

            selectedArtisanG(val, oldVal) {
                var valLength = val.length
                var oldValLength = oldVal.length

                if(valLength > oldValLength) {
                    var index = valLength-1
                    if(!this.selectedArtisan.includes(val[index])) {
                        this.selectedArtisan.push(val[index])
                    }
                }

            }
          
        },

        directives: {
            'b-tooltip': VBTooltip,
            Ripple
        },
        
    }
</script>

<style>
    .per-page-selector {
        width: 90px;
    }

    .checkboxCol {
        width: 100px;
    }

    .checkboxCol .custom-checkbox {
        display: inline-flex;
    }

    .v-select:not(.vs--single) .vs__deselect svg {
        fill: #fff;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>