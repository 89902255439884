<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="!interData"
    >
      <h4 class="alert-heading">
        Error
      </h4>
      <div class="alert-body">
        Aucune intervention trouvé avec cet ID intervention. Vérifier
        <b-link
          class="alert-link"
          :to="{ name: 'interventions-list'}"
        >
          liste des interventions
        </b-link>
        pour les autres interventions.
      </div>
    </b-alert>

    <template v-if="interData && interData.id">
      <!-- First Row -->
      <b-row>
        <b-col cols="4">
          <inter-info-card :data="interData"/>
        </b-col>

        <b-col cols="4">
          <client-info-card :data="interData.contact"/>
        </b-col>

        <b-col cols="4">
          <statistic-card :Affectation_nbrs="Affectation_nbrs" :achat_nbrs="achat_nbrs" />
        </b-col>
      </b-row>

    </template>

    <template v-if="interData && interData.id">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <artisans-card :data="interData" @change_affectation_nbrs="change_affectation_nbrs"  @change_achat_nbrs="change_achat_nbrs" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { mapActions } from "vuex"
import {
  BRow, BCol, BAlert, BLink,BCard
} from 'bootstrap-vue'

import InterInfoCard from './InterInfoCard.vue'
import ClientInfoCard from './ClientInfoCard.vue'
import StatisticCard from './StatisticCard.vue'
import ArtisansCard from './ArtisansCard.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    InterInfoCard,ClientInfoCard,StatisticCard,ArtisansCard
  },

  data() {
    return {
      interData: {id:null},
      Affectation_nbrs : 0,
      achat_nbrs : 0
    }
  },

  computed: {
  //   ...mapGetters('services', {'services' : 'GET_ITEMS'}),

    
  },

  methods: {
    ...mapActions('demandes', {'fetch' : 'getItem'}),

    notification(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
          text: text,
          variant
        },
      })
    },

    change_affectation_nbrs(value) {
      this.Affectation_nbrs = value
    },

    change_achat_nbrs(value) {
      this.achat_nbrs = value
    }

    
  },

  created() {
    this.fetch(this.$route.params.id)
        .then((result) => {
          this.interData = result.data

          this.Affectation_nbrs = result.data.artisans.length

          this.achat_nbrs = result.data.artisans.filter(item => {
            return item.date_achat != null
          }).length
        })
        .catch(() => {
          this.interData = null
        })
    
  },

  watch: {

  },
 
}
</script>

<style>

</style>
