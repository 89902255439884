<template>
    <b-card class="h-100">
      <table class="mt-2 mt-xl-0 w-100">
        <tr>
          <th class="pb-75">
            <span class="font-weight-bold">Catégorie</span>
          </th>
          <td class="pb-75">
            {{ data.service.service.name }}
          </td>
        </tr>
        <tr>
          <th class="pb-75">
            <span class="font-weight-bold">Type de problème</span>
          </th>
          <td class="pb-75 text-capitalize">
            {{ data.service.titre }}
          </td>
        </tr>
        <tr>
          <th class="pb-75">
            <span class="font-weight-bold">Etat d'urgence</span>
          </th>
          <td class="pb-75 text-capitalize">
            <span v-if="data.urgence">Urgent</span>
            <span v-else>Sur rendez-vous</span>
          </td>
        </tr>
        <tr>
          <th class="pb-75">
            <span class="font-weight-bold">Date intervention</span>
          </th>
          <td class="pb-75 text-capitalize">
            {{ data.date }}
          </td>
        </tr>
        <tr>
          <th class="pb-75">
            <span class="font-weight-bold">Description problème</span>
          </th>
          <td class="pb-75 text-capitalize">
            {{ data.description }}
          </td>
        </tr>

        <tr>
          <th class="pb-75">
            <span class="font-weight-bold">Coût du contact</span>
          </th>
          <td class="pb-75 text-capitalize">
            {{ data.cout }}€
          </td>
        </tr>
      </table>
    </b-card>
</template>

<script>
  
  import {
    BRow, BCol, BTable, BBadge, BCard
  } from 'bootstrap-vue'


  export default {
    components: {
      BRow,
      BCol,
      BTable,
      BBadge,
      BCard
    },

    props: {
      data: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        
      }
    },

  }
</script>

<style lang="scss" scoped>
  
</style>


