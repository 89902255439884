<template>
    <b-card class="h-100">  
      <b-row>
          <b-col sm="12" class="d-flex justify-content-center">
                <div class="text-center">
                    <b-card-text class="mb-50">
                        Affecté
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ Affectation_nbrs }}</span>
                </div>

                <!-- apex chart -->
                <vue-apex-charts
                    type="radialBar"
                    height="220"
                    :options="supportTrackerRadialBar.chartOptions"
                    :series="supportTrackerRadialBar.series"
                />

                <div class="text-center">
                    <b-card-text class="mb-50">
                        Acheté
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ achat_nbrs }}</span>
                </div>
            </b-col>
      </b-row>
    </b-card>
</template>

<script>
  
  import {
    BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'


  export default {
    components: {
      VueApexCharts,
        BCard,
        BCardHeader,
        BCardTitle,
        BDropdown,
        BDropdownItem,
        BCardText,
        BCardBody,
        BRow,
        BCol,
    },

    props: ['Affectation_nbrs', 'achat_nbrs'],

    data() {
      return {
        supportTrackerRadialBar: {
            chartOptions: {
                plotOptions: {
                    radialBar: {
                    size: 150,
                    offsetY: 20,
                    startAngle: -150,
                    endAngle: 150,
                    hollow: {
                        size: '65%',
                    },
                    track: {
                        background: '#fff',
                        strokeWidth: '100%',
                    },
                    dataLabels: {
                        name: {
                        offsetY: -5,
                        color: '#5e5873',
                        fontSize: '1rem',
                        },
                        value: {
                        offsetY: 15,
                        color: '#5e5873',
                        fontSize: '1.714rem',
                        },
                    },
                    },
                },
                colors: [$themeColors.danger],
                fill: {
                    type: 'gradient',
                    gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: [$themeColors.primary],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                    },
                },
                stroke: {
                    dashArray: 8,
                },
                labels: ['Acheté'],
            }
        },
      }
    },

    created() {
        this.supportTrackerRadialBar.series = [this.Affectation_nbrs > 0 ? (this.achat_nbrs / this.Affectation_nbrs * 100).toFixed(0) : 0]
    },

    watch: {
        Affectation_nbrs() {
            this.supportTrackerRadialBar.series = [this.Affectation_nbrs > 0 ? (this.achat_nbrs / this.Affectation_nbrs * 100).toFixed(0) : 0]
        },

        achat_nbrs() {
            this.supportTrackerRadialBar.series = [this.Affectation_nbrs > 0 ? (this.achat_nbrs / this.Affectation_nbrs * 100).toFixed(0) : 0]
        },
    }

  }
</script>

<style lang="scss" scoped>
  
</style>


